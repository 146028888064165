.section-tv-boxes {
    position: relative; /* Necessário para posicionar corretamente o pseudo-elemento */
    background-image: linear-gradient(to right top, #124566, #2284c5, #2d7eb4, #206593, #40a7ec);
    width: 80%;
    margin: 30px auto;
    padding: 20px;
    border-radius: 10px;
    overflow: hidden; /* Para garantir que o pseudo-elemento não ultrapasse os limites da borda */
    
}

.section-tv-boxes p {
    color: #fff;
    text-align: center; /* Centraliza o texto */
    margin: 0; /* Remove margens padrão */
}

.section-tv-boxes h1 {
    color: #fff;
    font-size: 28px;

}

.section-tv-boxes .section-title {
    color: #ffffff;
    margin-left: 30px;
    text-align: left; /* Centraliza o texto */
    font-size: 40px;

}

.section-tv-boxes .section-subtitle {
    text-align: left; /* Centraliza o texto */
    margin-left: 30px;
    font-size: 15px;
    font-weight: 100;
    margin-bottom: 10px;
}

.section-tv-boxes .left-tv-column {
    flex: 1;

}

.section-tv-boxes .right-tv-column {
    flex: 1;

}
